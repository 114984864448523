import store from '../store';

const getMenu = function () {
    const user = store.getters.currentUser;

    const menu = [
        {
            icon: '',
            text: 'Home',
            link: '/'
        },
        {
            text: 'About Us',
            link: '/about/'
        },
        {
            icon: '',
            text: 'Services',
            link: '/services/'
        },
        {
            icon: '',
            text: 'News',
            link: '/news/'
        },
        {
            icon: '',
            text: 'Careers',
            link: '/Career/'
        },
        {
            icon: '',
            text: 'Contact Us',
            link: '/Contact/'
        }

    ]
    ;

    const adminMenu = [
        {
            text: 'Admin Master Data',
            link: '#',
            items: [
                {
                    text: 'Master Data',
                    link: '/app/master-data/'
                },
                {
                    text: 'Master Data',
                    link: '/app/master-data/'
                }
            ]
        }
    ];

    // Do something similar for any number of roles
    if (user && user.admin === true) {
        return [
            ...menu,
            ...adminMenu
        ];
    }
    return [
        ...menu
    ];
};
export default getMenu;

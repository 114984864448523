<template>
    <div class="bg-primary py-1 pos-fixed">
        <div class="container">
            <div class="fl-x fl-j-sb fl-a-c">
                <div  class="social-media bg-primary ">
                    <a href="">
                        <span class="social-icon border-r-1 p-1">
                            <img
                                 src="../assets/web/social-media-icons/fb.png" alt=""
                                 class="img-fluid icon-sm"/>
                        </span>
                    </a>
                    <a href="">
                        <span class="social-icon border-r-1 p-1">
                            <img src="../assets/web/social-media-icons/twitter.png" alt=""
                                 class="img-fluid icon-sm"/>
                        </span>
                    </a>
                    <a href="">
                        <span class="social-icon border-r-1 p-1">
                            <img src="../assets/web/social-media-icons/whatsapp.png" alt=""
                                 class="img-fluid icon-sm"/>
                        </span>
                    </a>
                    <a href="">
                        <span class="social-icon border-r-1 p-1">
                            <img src="../assets/web/social-media-icons/insta.png" alt=""
                                 class="img-fluid icon-sm"/>
                        </span>
                    </a>
                </div>
<!--                <div v-else class="social-media bg-primary"/>-->
                <div class="fl-x-res">
                    <div class="fl-x fl-a-c mr-3">
                        <div class="border-r-1 border-rounded text-center fl-x-cc mb-lg-0 mr-2">
                            <!--                            <span class="header-icon">-->
                            <a :href="'tel:'"
                               class="text-decoration-none header-icon mt-2">
                                <img src="../assets/web/home/icons/phone.svg" alt=""
                                     class="img-fluid">
                            </a>
                        </div>
                        <div class="text-center-res">
                            <p class="font-inter-light mb-0 fs--1">
                                Call us:<a :href="'tel:'"
                                           class="text-decoration-none">+91 9875632152</a>
                            </p>
                        </div>
                    </div>
                    <!--                    <div class="text-center-res px-4" v-if="currentRoute ==='About'">-->
                    <!--                        <a href="" class="text-decoration-none">Know Legal Information-->
                    <!--                            <span class="ml-2">-->
                    <!--                                <i class="fa fa-chevron-right fs--1 font-weight-normal"></i>-->
                    <!--                            </span>-->
                    <!--                        </a>-->
                    <!--                    </div>-->
<!--                    <div class="fl-x-res fl-a-c">-->
<!--                        <div class="border-r-1 border-rounded text-center fl-x-cc mb-3 mb-lg-0 mr-2">-->
<!--                            <span class="header-icon mt-2"><img src="../../assets/web/home/icons/whatsapp.png" alt=""-->
<!--                                                           class="img-fluid"></span>-->
<!--                        </div>-->
<!--                        <div class="text-center-res">-->
<!--                            <a class="font-inter-light mb-0 fs&#45;&#45;1 text-decoration-none" :href="getWGroup">Join our-->
<!--                                Whatsapp group</a>-->
<!--                        </div>-->
<!--                    </div>-->
                </div>
            </div>
        </div>

    </div>

</template>
<script>
export default {
    name: 'TopHeader',
    props: {
        details: { type: Object, default: null },
        currentRoute: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            isAboutPage: this.$router.currentRoute.name,
            showLegalInfo: false
        };
    },
    watch: {
        isAboutPage (val) {
            console.log('isAbout page', val);
            if (val === 'About') {
                this.showLegalInfo = true;
            } else {
                this.showLegalInfo = false;
            }
        }
    }
};
</script>
<style scoped lang="scss">
.d-inline {
    display: inline;
}

.pos-fixed {
    position: fixed;
    width: 100%;
    z-index: 999;
}

.border-rounded {
    border-radius: 50%;
}

.w-7p {
    //width: 7%;
    width: 16px;
}

.icon-item {
    position: relative;
    width: 28px;
    height: 28px;
    background-color: var(--color-secondary);
    border-radius: 50%;
    margin: 0;

    i {
        position: fixed;
        z-index: 1001 !important;
    }

    //::before {
    //    content: "";
    //    width: 16px;
    //    height: 16px;
    //    position: absolute;
    //    background-color: var(--color-secondary);
    //    border-radius: 50%;
    //    z-index: 1000 !important;
    //}
}

//.social-icon {
//    padding: 1rem;
//    transition: all 300ms ease-in-out;
//    border: 2px solid;
//    display: inline-flex;
//    align-items: center;
//    justify-content: center;
//    height: 40px;
//    width: 40px;
//    color: var(--color-white);
//    background: #c9c9c926;
//    margin-left: 10px;
//    text-decoration: none;
//    cursor: pointer;
//
//    &:hover {
//        opacity: 0.8;
//        cursor: pointer;
//
//        background-color: var(--primary-900);
//        color: black;
//    }
//}
.border-abs {
    //border-bottom: var(--border-1) solid var(--border-color) !important;
    ::after {
        content: '';
        position: absolute;
        height: 1px;
        width: 100%;
        bottom: 0;
        background-color: var(--border-color);
    }
}

.header-icon {
    width: 1.8rem;
    height: auto;
}
</style>

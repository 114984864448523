<template>
    <div class="bg-2 pos-r"  id="top">
        <div class="nav-container top-margin">
            <lego-nav-bar drop-down-animation="fade" drop-down-animation-mobile="fade" :colored="false" :items="menu" shadow="0" class="container">
                <template #logo>
                    <router-link to="/">
                        <img src="../assets/web/logo/Logo.png" alt="" class="logo">
                    </router-link>
                </template>
            </lego-nav-bar>
        </div>

        <div class="bg-2">
            <top-header></top-header>
            <transition name="fade" mode="out-in">
                <router-view>

                </router-view>
            </transition>
        </div>
        <main-footer></main-footer>
<!--        <div class="min-rob">-->
<!--                <img src=".././assets/web/Images/robo.jpg">-->
<!--            </div>-->
        <div>
            <mini-robo-questionaire></mini-robo-questionaire>
        </div>

<!--        <Footer/>-->
<!--        <div class="p-3">-->
<!--            Powered by Xeoscript Technologies-->
<!--        </div>-->
    </div>

</template>

<script>
import LegoNavBar from 'lego-framework/src/components/NavBar';
import getMenu from '../data/menu';
import TopHeader from '@components/TopHeader';
import MainFooter from '@components/MainFooter';
import MiniRoboQuestionaire from '@components/ui/MiniRobo-Questionaire';

export default {
    components: {
        MiniRoboQuestionaire,
        MainFooter,
        TopHeader,
        LegoNavBar
    },
    data () {
        return {
            menu: getMenu(),
            value: ''
        };
    },
    metaInfo: {
        titleTemplate: '%s - Project Name',
        htmlAttrs: {
            lang: 'en',
            amp: true
        }
    },
    name: 'MainLayout'
};
</script>

<style>
.min-rob img {
    position: absolute;
    width: 80px;
    height: 80px;
    //top: 846px;
    right: 45px;
    bottom: 0px;
    border-radius: 50%;
}
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
}
/*.layout-p-top{*/
/*    */
/*}*/

</style>

<template>
    <footer class="text-white of-h pos-r" :class="{'bg-5': grayBg}">
        <div class="curved-top">
            <div class="container pt-6">
                <div class="row">
                    <div class="col-12 mb-6">
                        <base-heading-with-info color="white" :text-uppercase="false" :bottom-tag="true"
                                                class="mb-4" title="Subscribe to our Newsletter"
                                                info="There are many variations of passages of Lorem Ipsum available alteration in some form, by injected"
                        >
                        </base-heading-with-info>
                        <div class="fl-x fl-j-c">
                            <div class="fl-x fl-a-c">
                                <validated-input class="mr-3 mb-0 min-w-lg-20re c-input-transparent"
                                                 placeholder="Enter your email"
                                                 border-radius="3"></validated-input>
                                <btn design="" class="btn-bg-white" border-radius="4" text="Subscribe Now"></btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pt-5 top-footer-cont bg-primary border-bottom">
            <div class="container">
                <div class="px-lg-7">
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-3 mb-3 mb-lg-0">
                            <div class="">
                                <a href="" class="">
                                    <img src="../assets/web/logo/white-logo.svg" class="img-fluid footer-logo" alt="">
                                </a>
                                <p class="mt-3 fs-lg--1">
                                    ADVENT is an online platform providing debtor collection services by submitting your
                                    claims online.
                                </p>
                            </div>
                        </div>

                        <!--                    <div class="col-12 col-md-6 col-lg-2 d-sm-none mb-4"/>-->

                        <div class="col-12 col-md-6 col-lg-3 d-sm-none mb-3 mb-lg-0">
                            <div class="fl-y fl-a-s ml-lg-5">
                                <h2 class="mb-4 font-lato-bold fs-lg-1">Company</h2>
                                <ul class="list-unstyled mb-3 ul-footer">
                                    <li class="mb-1">
                                        <router-link to="/" class="d-block">About Us</router-link>
                                    </li>
                                    <li class="c-mb-11">
                                        <router-link to="/about-us/" class="d-block ">General Communication Section
                                        </router-link>
                                    </li>
                                    <li class="c-mb-11">
                                        <router-link to="/course-offered/" class="d-block ">Blog</router-link>
                                    </li>
                                    <li class="c-mb-11">
                                        <router-link to="/training-centers/" class="d-block">Careers
                                        </router-link>

                                    </li>
                                    <li class="c-mb-11">
                                        <router-link to="/contact/" class="d-block">FAQ</router-link>
                                    </li>
                                    <li class="c-mb-11">
                                        <router-link to="/contact/" class="d-block">Get help</router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-12 col-md-6 col-lg-3 d-sm-none mb-4 mb-lg-0">
                            <div class="fl-y fl-a-s">
                                <h3 class="mb-4 font-lato-bold fs-lg-1">Useful Links</h3>
                                <ul class="list-unstyled mb-3 ul-footer">
                                    <li class="mb-1">
                                        <router-link to="/" class="d-block">About Us</router-link>
                                    </li>
                                    <li class="c-mb-11">
                                        <router-link to="/about-us/" class="d-block ">General Communication Section
                                        </router-link>
                                    </li>
                                    <li class="c-mb-11">
                                        <router-link to="/course-offered/" class="d-block ">Blog</router-link>
                                    </li>
                                    <li class="c-mb-11">
                                        <router-link to="/training-centers/" class="d-block">Careers
                                        </router-link>

                                    </li>
                                    <li class="c-mb-11">
                                        <router-link to="/contact/" class="d-block">FAQ</router-link>
                                    </li>
                                    <li class="c-mb-11">
                                        <router-link to="/contact/" class="d-block">Get help</router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-12 col-md-6 col-lg-3 mb-3 d-sm-none mb-lg-0">
                            <div class="fl-y fl-a-s w-100">
                                <h3 class="mb-4 font-lato-bold fs-lg-1">More Links</h3>
                                <ul class="list-unstyled mb-3 ul-footer">
                                    <li class="mb-1">
                                        <router-link to="/" class="d-block">About Us</router-link>
                                    </li>
                                    <li class="c-mb-11">
                                        <router-link to="/about-us/" class="d-block ">General Communication Section
                                        </router-link>
                                    </li>
                                    <li class="c-mb-11">
                                        <router-link to="/course-offered/" class="d-block ">Blog</router-link>
                                    </li>
                                    <li class="c-mb-11">
                                        <router-link to="/training-centers/" class="d-block">Careers
                                        </router-link>

                                    </li>
                                    <li class="c-mb-11">
                                        <router-link to="/contact/" class="d-block">FAQ</router-link>
                                    </li>
                                    <li class="c-mb-11">
                                        <router-link to="/contact/" class="d-block">Get help</router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-12 col-lg-3 mb-lg-0 d-lg-none d-md-none">
                            <div class="fl-y fl-a-s">

                                <base-accordion color="" heading="Quick Links">
                                    <template #default>
                                        <div class="fl-x fl-j-s ml-5">
                                            <ul class="list-unstyled mb-3 ul-footer">
                                                <li class="c-mb-11">
                                                    <router-link to="/" class="d-block ">Home</router-link>
                                                </li>
                                                <li class="c-mb-11">
                                                    <router-link to="/about/" class="d-block ">About Us
                                                    </router-link>
                                                </li>
                                                <li class="c-mb-11">
                                                    <router-link to="/about/" class="d-block">Course Offered
                                                    </router-link>
                                                </li>
                                                <li class="c-mb-11">
                                                    <router-link to="/contact/" class="d-block">Training Centers
                                                    </router-link>

                                                </li>
                                                <li class="c-mb-11">
                                                    <router-link to="/contact/" class="d-block">Contact Us</router-link>
                                                </li>
                                            </ul>
                                        </div>
                                    </template>
                                </base-accordion>
                            </div>
                        </div>

                        <div class="col-12 col-lg-3 mb-lg-0 d-lg-none d-md-none">
                            <div class="fl-y fl-a-c">
                                <base-accordion color="" heading="Training Centers">
                                    <template #default>
                                        <div class="fl-y fl-a-s ml-5">
                                            <ul class="list-unstyled mb-3 ul-footer">
                                                <li class="c-mb-11">
                                                    <router-link to='/' class="d-block">Mattupatti</router-link>
                                                </li>
                                                <li class="c-mb-11">
                                                    <router-link to='/' class="d-block">Dhoni</router-link>
                                                </li>
                                                <li class="c-mb-11">
                                                    <router-link to="/" class="d-block">Kulathupuzha</router-link>
                                                </li>
                                                <li class="c-mb-11">
                                                    <router-link to="/" class="d-block">Koothattukulam</router-link>
                                                </li>
                                                <li class="c-mb-11">
                                                    <router-link to="/" class="d-block">Kolahalamedu</router-link>
                                                </li>
                                            </ul>
                                        </div>
                                    </template>
                                </base-accordion>
                            </div>
                        </div>

                        <div class="col-12 col-lg-3 mb-3 mb-lg-0 d-lg-none d-md-none">
                            <base-accordion color="" heading="Contact Us" :border-bottom="true">
                                <template #default>
                                    <div class="fl-y fl-a-s ml-5">
                                        <ul class="list-unstyled mb-3 ul-footer">
                                            <li class="c-mb-11">
                                                <router-link to='' class="d-block">Phone</router-link>
                                            </li>
                                            <li class="c-mb-11">
                                                <router-link to='/' class="d-block">+91 9675 897 365</router-link>
                                            </li>
                                            <li class="c-mb-11">
                                                <router-link to='/' class="d-block">+91 9675 897 365</router-link>
                                            </li>
                                            <li class="c-mb-11">
                                                <span>Email : </span>
                                                <router-link to="mailto:info@kldb.com" type="mail" class="">
                                                    info@kldb.com
                                                </router-link>
                                            </li>
                                            <li class="c-mb-11">
                                                <span>Fax : </span>
                                                <router-link to="/" class="">info@kldb.com</router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </template>
                            </base-accordion>

                        </div>

                        <div class="col-12">
                            <div class="fl-x fl-j-c py-2">
                                <div class="social-media">
                                    <a href="/">
                                    <span
                                        class="social-icon border-r-1 px-md-4 pr-lg-4 px-2">
<!--                                       <i class="fa fa-facebook"></i>-->
                                        <img src="../assets/web/social-media-icons/twitter.png" class="icon-sm-1"
                                             alt="">
                                    </span>
                                    </a>
                                    <a href="/">
                                    <span
                                        class="social-icon border-r-1 px-md-4 pr-lg-4 px-2">
                                        <img src="../assets/web/social-media-icons/fb.png" class="icon-sm-1" alt="">
                                    </span>
                                    </a>
                                    <a href="/">
                                    <span
                                        class="social-icon border-r-1 px-md-4 pr-lg-4 px-2">
                                        <img src="../assets/web/social-media-icons/whatsapp.png" class="icon-sm-1"
                                             alt="">
                                    </span>
                                    </a>
                                    <a href="/">
                                    <span
                                        class="social-icon border-r-1 px-md-4 pr-lg-4 px-2">
                                        <img src="../assets/web/social-media-icons/linkedin.png" class="icon-sm-1"
                                             alt="">
                                    </span>
                                    </a>
                                    <a href="/">
                                    <span
                                        class="social-icon border-r-1 px-md-4 pr-lg-4 px-2">
                                        <img src="../assets/web/social-media-icons/google-plus.png" class="icon-sm-1"
                                             alt="">
                                    </span>
                                    </a>
                                    <a href="/">
                                    <span
                                        class="social-icon border-r-1 px-md-4 pr-lg-4 px-2">
                                        <img src="../assets/web/social-media-icons/insta.png" class="icon-sm-1" alt="">
                                    </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="border-top pos-r credit-section">
                <!--                <div class="line-top"></div>-->
                <div class="container py-2">
                    <div class="px-lg-7 pos-r text-white-1">
                        <div class="row">
                            <div class="col-12">
                                <div class="c-fl-te-c">
                                    <p class="mb-lg-0 text-center fs-lg--1">&copy; Copyright {{
                                            new Date().getFullYear()
                                        }}
                                        Kerala
                                        Live Stock Development Board
                                    </p>
                                    <ul class="fl-x text-decoration-none fs-lg--1 footer-credit-links">
                                        <router-link class="px-2" to="/Copyright">Copyright</router-link>
                                        <router-link class="px-2" to="/Copyright">Disclaimer</router-link>
                                        <router-link class="px-2" to="/Copyright">Privacy Policy</router-link>
                                        <router-link class="px-2" to="/Copyright">Sitemap</router-link>
                                        <router-link class="px-2" to="/Copyright">FAQ</router-link>
                                    </ul>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import BaseAccordion from '@components/ui/BaseAccordion';

export default {
    name: 'MainFooter',

    components: { BaseAccordion },

    props: {
        details: { type: Object, default: null },

        currentRoute: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            grayBg: false,
            footerBgPages: ['Career-InnerPage', 'Career', 'Career-Apply']
        };
    },
    mounted () {
        // const pages = ['Career-InnerPage', 'Career', 'Faq', 'MeetOur-Team'];
        if (this.footerBgPages.includes(this.$router.currentRoute.name)) {
            // alert('abc');
            this.grayBg = true;
        } else {
            this.grayBg = false;
        }
    },
    watch: {
        $route (to, from) {
            // const pages = ['Career-InnerPage', 'Career'];
            if (this.footerBgPages.includes(to.name)) {
                // alert('abc');
                this.grayBg = true;
            } else {
                this.grayBg = false;
            }
        }
    },
    // computed: {
    //     grayBg () {
    //         if (this.$router.currentRoute.name === 'Career') {
    //             return true;
    //         }
    //         return false;
    //     }
    // },
    methods: {
        // routingPrevDefault (e) {
        //     console.log('routingPreventDefault', e.target.href);
        //     this.$router.push(e.target.href);
        // }
    }
};
</script>

<style scoped lang="scss">
.underline-item {
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -7px;
    left: 0;
    background-color: rgba(228, 233, 242, 0.6);
}

.social-links {
    display: flex;
    //width: 80%;
    justify-content: flex-start;
    list-style: none;

    li {
        :not(:last-child) {
            transition: all ease-in-out 300ms;
            border: 2px solid #ddd;
            opacity: 0.6;
            border-radius: 0.5rem;
            height: 45px;
            width: 45px;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
                font-size: 1.6rem;
                color: white;
            }

            &:hover {
                //transform: scale(1.03);

                i {
                    color: #9e9e9e;
                }
            }
        }
    }
}

.top-footer-cont {
    //background-color: #262626;
    //color: #888888;

    .text-head {
        //font-weight: bold !important;
        //font-size: 1.2rem;
        font-family: 'inter-semi-bold', sans-serif;
        margin-bottom: var(--spacer-4);
        color: #fafafa;
    }

    .text-details {
        font-weight: bold !important;
        font-family: 'Montserrat', sans-serif;
        color: #ffffff;
    }

    .list {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            position: relative;
            padding: 10px 0;
            border-bottom: 1px solid #333333;
            transition: 0.3s;

            &:hover {
                color: #cccccc;

                &:after {
                    color: #cccccc;
                }
            }

            &:after {
                font-family: "FontAwesome";
                content: "\f105";
                position: absolute;
                top: 10px;
                right: 0;
                font-size: 14px;
                color: #999999;
            }
        }
    }

}

ul {
    a {
        text-decoration: none;
    }
}

a:hover {
    color: #cccccc;
}

.bottom-footer-cont {
    background-color: #1b1b1b;
    //color: #777777;
    color: var(--color-white);

    .bottom-footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .social-media-icons {
            li {
                display: inline-block;
                font-size: 1.4rem;

                &:not(:last-child) {
                    margin-right: 20px;
                }
            }
        }
    }

    @media (min-width: 992px) {
        .bottom-footer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }
}

.w-60 {
    width: 60% !important;
}

//.text-white {
//    &:hover {
//        opacity: 0.6 !important;
//    }
//}

.ul-footer {
    li a {
        font-size: 0.9rem;
    }
}

.c-address {
    font-size: 0.9rem;
    font-style: normal !important;
    //line-height: 2rem;
}

.line-top {
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(228, 233, 242, 0.2);
}

.border-bottom {
    border-bottom: 1px solid rgba(228, 233, 242, 0.2);
}

.border-top {
    border-top: 1px solid rgba(228, 233, 242, 0.2);
}

.footer-logo-container {
    img {
        min-height: 3.2rem;
    }
}

.social-icon {
    i {
        font-size: 1.7rem;
        color: rgba(255, 255, 255, 0.7);
    }
}

.footer-bg {
    //background: url("../assets/web/landing/footer-bgi.jpg");
    background-position: center;
    background-size: cover;
}

.footer-logo {
    height: 6.5rem;
}

.footer-credit-links {
    a {
        position: relative;
        padding: 0 var(--spacer-2);

        &:not(:last-child)::after {
            content: '';
            position: absolute;
            right: 0;
            height: 60%;
            transform: translateY(40%);
            width: 1.2px;
            background-color: rgba(255, 255, 255, 0.7) !important;
        }

    }
}

.curved-top {
    //position: absolute;
    //top: 50%;
    //left: 50%;
    //transform: translate(-50%, -50%);
    //height: 100px;
    //width: 200px;
    //border-radius: 150px 150px 0 0;
    //border-radius: 50% 50% 0 0;
    //background-color: green;
    position: relative;
    z-index: 0;

    &::before {
        content: '';
        position: absolute;
        background-color: var(--color-primary) !important;
        //background: url("../assets/web/footer/curved-bg.png") !important;
        border-radius: 60% 60% 0 0;
        //top: -1rem;
        //z-index: 999;
        //width: 108%;
        //height: auto;
        width: 135vw;
        height: 150vh;
        margin-left: -18%;
        //ver II
        width: 180vw;
        margin-left: -40%;
        overflow-x: hidden !important;
        height: 130vh;
        @media (min-width: 992px) {
            height: 250vh;
        }
        //z-index: -1;
    }
}

.pt-15rem {
    padding-top: 15rem !important;
}

.pos-a-t {
    position: absolute;
    top: 0;
    height: 100vh;
}

.min-w-lg-20re {
    @media (min-width: 768px) {
        min-width: 20rem !important;
    }
}
</style>

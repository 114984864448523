<template>
    <div class="bg-4 z-index-mx">
        <img @click="showGetQuoteForm" class="btn-abs-bottom"
             src="../../assets/web/Images/robo.jpg" alt="">
<!--        <modal title="Ventura eAuction" class="c-modal no-close c-p-0 bs-5 notification-modal"-->
<!--               enter-animation="animated slideInRight" exit-animation="animated slideOutRight" ref="GetAQuote"-->
<!--               :no-close-on-backdrop="false" width="100p" header-color="primary">-->
<!--            <div class="bg-primary p-2 fl-te-c logo">-->
<!--                <img src="../../assets/web/home/logo-without-text.png" class="img-fluid" alt="">-->
<!--                <span>Ventura eAuction</span>-->
<!--            </div>-->
            <div>

<!--                <chat-message-item></chat-message-item>-->
<!--                <chat-message-item></chat-message-item>-->
                <ul class="list-unstyled ml-5">
                    <li>
<!--                        <chat-selection-item></chat-selection-item>-->
                    </li>
                </ul>
            </div>
<!--        </modal>-->
    </div>
</template>
<script>
// import GetQuoteModal from '@components/backend/popups/GetQuoteModal';

// import ChatMessageItem from '@components/ui/ChatMessageItem';
// import ChatSelectionItem from '@components/ui/ChatSelectionItem';

export default {
    name: 'MiniRobo-Questionaire',
    components: { },
    methods: {
        showGetQuoteForm () {
            // alert('abc');
            this.$refs.GetAQuote.show();
        }
    }
}; </script>
<style scoped lang="scss">
.btn-abs-bottom {
    position: fixed;
    bottom: 0;
    right: 30px;
    bottom: 90px;
    border-radius: 50%;
    z-index: 9;
    width: 4.6875rem;
    height: auto;
    cursor: pointer;
    //filter: drop-shadow(0px 13px 4px rgba(17, 51, 137, 0.4));
}

//.c-modal {
//    display: flex;
//    align-items: center;
//    justify-content: unset;
//    z-index: 1;
//    background-color: transparent;
//    bottom: 2rem;
//    top:7rem;

    //.modal-header {
    //    display: block !important;
    //    background-color: var(--bg-4);
    //    background-color: var(--color-secondary);
    //    color: var(--color-primary);
    //    border-bottom: 0.5px solid var(--color-primary);
    //
    //    h5 {
    //        display: block !important;
    //        text-align: center;
    //        margin-bottom: 0;
    //    }
    //
    //    div {
    //        display: none;
    //    }

    //}

    //.modal {
    //    margin: 0;
    //    position: absolute;
    //    top: 0.5rem;
    //    top: 50%;
    //    transform: translate(0, -50%);
    //    right: 30px;
    //    left: unset;
    //    border: 1px solid var(--color-primary);
    //    width: 360px;
    //    box-shadow: 0 0 7px 0 #00000038;
    //
    //    .modal-body {
    //        padding: 0.5rem;
    //        /*background-color: var(--bg-5);*/
    //        position: relative;
    //        //overflow: auto;
    //        min-height: 100px;
    //        max-height: 65vh;
    //    }
    //
    //}

//    .notification-full-view {
//
//        .modal-header {
//            background-color: var(--color-primary);
//            color: var(--color-white);
//
//            div {
//                display: inline-flex;
//            }
//
//        }
//
//        .modal {
//            margin: 0;
//            position: absolute;
//            top: 58px;
//            top: 50%;
//            transform: translate(-50%, -50%);
//            right: unset;
//            left: 50%;
//            width: 50rem;
//            box-shadow: 0 0 7px 0 #00000038;
//
//            .modal-body {
//                padding: 0.5rem;
//                /*background-color: var(--bg-5);*/
//                position: relative;
//                overflow: auto;
//                min-height: 100px;
//                max-height: 65vh;
//            }
//        }
//    }
//}

</style>
